import { useMemo } from 'react';
import type { AuthorType, UserType } from 'app/entities';
import { guard } from 'utils';

type Params = {
  user?: Pick<UserType, 'name' | 'surname' | 'photo'> | Pick<AuthorType, 'name' | 'surname' | 'photo' | 'owner'> | null;
  src?: string;
  name?: string;
  defaultName?: string;
};

export const useAvatarParams = ({ user, src, name, defaultName }: Params) => {
  const finalSrc = useMemo(() => {
    if (user && guard.isUser(user) && user?.photo) {
      return user.photo.url;
    }
    if (user && guard.isAuthor(user) && user?.owner?.photo) {
      return user.owner.photo.url;
    }
    if (user && guard.isAuthor(user) && user?.photo) {
      return user.photo.url;
    }
    return src;
  }, [user, src]);

  const finalName = useMemo(() => {
    let tmpName = name || defaultName || '';
    let tmpSurname = '';
    if (guard.isUser(user)) {
      tmpName = user.name;
      tmpSurname = user.surname;
    }
    if (guard.isAuthor(user) && user?.owner) {
      tmpName = user.owner.name;
      tmpSurname = user.owner.surname;
    }
    if (guard.isAuthor(user) && !user?.owner) {
      tmpName = user.name || '';
      tmpSurname = user.surname || '';
    }
    return `${tmpName ?? ''} ${tmpSurname ?? ''}`.trim();
  }, [name, defaultName]);

  return useMemo(
    () => ({
      finalName,
      finalSrc,
    }),
    [finalName, finalSrc],
  );
};
