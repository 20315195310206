import { memo, useCallback, useEffect, useMemo } from 'react';
import { Box, CircularProgress } from '@mui/joy';

import { useListLayout, useSearchMethod } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

import AdaptivePage from 'components/AdaptivePage';
import List from 'components/List';
import { useScrollMethods } from 'components/Scroller';

import Card from 'widgets/Card';

import useSearchState from '../../model/useSearchState';

import StateMessage from '../../ui/StateMessage';

const Regular = () => {
  const dispatcher = useDispatcher();
  const { scrollTo } = useScrollMethods();
  const { scope: searchMethodScope, type: searchMethodType } = useSearchMethod();

  const sequence = useSelector(searchStore.selectors.sequence);
  const paginationInfo = useSelector(searchStore.selectors.paginationInfo);
  const isPageLoading = useSelector(searchStore.selectors.isPageLoading);
  const listMeta = useSelector(searchStore.selectors.listMeta);

  const { listLayout } = useListLayout('search.list.mode', 'row');

  const searchState = useSearchState();

  useEffect(() => {
    scrollTo(0);
  }, [listMeta.firstPage.isLoading]);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.search.loadNextPage();
  }, [listMeta.isConsistent]);

  const getKey = useCallback((item: (typeof sequence)[0]) => {
    if (!item || typeof item !== 'object') {
      return 'unknown';
    }
    return `${item.type}-${item.id}`;
  }, []);

  const renderItem = useCallback(
    ({ data }: any) => {
      const [highlight] = data?.searchMeta?.highlights || [];
      const extraData: Record<string, string> = {};
      if (highlight) {
        extraData.description = highlight;
      }

      return (
        <Card
          id={data.id}
          key={data.id}
          type={data.type}
          descriptionLineLimit={4}
          hasDescription
          hasAuthor
          hasSummary
          hasHighlight
          hasLink
          hasTags
          hasToCollection
          hasIndexed
          hasRemove={searchMethodScope === 'Library'}
          hasQueue={searchMethodScope === 'Global'}
        />
      );
    },
    [listLayout.isRow, searchMethodScope],
  );

  return (
    <AdaptivePage desktopHeaderLeftPanel="search" bodyMaxWidth={1280} paddingHorizontalScheme={{ 320: 16 }}>
      {searchState !== 'list' && <StateMessage />}
      {searchState === 'list' && (
        <>
          <Box height={48} sx={{ '@media (min-width: 768px)': { height: 0 } }} />
          <List
            items={sequence}
            getKey={getKey}
            renderItem={renderItem}
            columnsScheme={listLayout.isRow ? '320:1' : '320:1;528:2;672:3;960:4'}
            loading={isPageLoading}
            hasNextPage={(isPageLoading || sequence.length > 0) && paginationInfo.hasNext}
            onEndReached={handleNextPage}
          />
          <Box sx={{ '@media (min-width: 768px)': { height: 16 } }} />
        </>
      )}
      {searchState === 'list' && isPageLoading && (
        <Box display="flex" flexDirection="column" flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </AdaptivePage>
  );
};

export default memo(Regular);
