import { type ReactNode, memo } from 'react';
import { Box, CardContent, Typography } from '@mui/joy';

import { type RagMaterialType } from 'app/entities';

import CompositePoster from 'components/CompositePoster';

type MaterialProps = {
  data?: RagMaterialType;
  hasDescription?: boolean;
  descriptionLineLimit?: number;
  AuthorBlockComponent?: ReactNode;
};

const RagMaterial = (props: MaterialProps) => {
  const { data, hasDescription, descriptionLineLimit, AuthorBlockComponent } = props;

  return (
    <>
      <Box
        sx={{
          '@container (width > 400px )': {
            display: 'none',
          },
        }}
      >
        {data?.image && <CompositePoster sources={[data?.image]} radius={16} aspectRatio={4 / 3} />}
      </Box>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              fontSize={12}
              fontWeight={400}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
                '@container (width > 400px )': {
                  marginRight: '50%',
                },
              }}
            >
              {data?.originalUrl}
            </Typography>
            {data?.title && (
              <Typography
                fontSize={17}
                sx={{
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={600}
              >
                {data?.title?.trim?.()}
              </Typography>
            )}
            {hasDescription && data?.description && (
              <Typography
                fontSize={15}
                sx={{
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={400}
              >
                {data.description}
              </Typography>
            )}
          </Box>
          {data?.image && (
            <Box
              sx={{
                ml: 1,
                '@container (width <= 400px )': {
                  display: 'none',
                },
              }}
            >
              <CompositePoster width={150} sources={[data?.image]} radius={16} aspectRatio={16 / 9} />
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default memo(RagMaterial);
