import { type FC, useEffect } from 'react';

import credentials from 'services/api/credentials';
import getAppId from 'utils/getAppId';
import getSessionId from 'utils/getSessionId';

const UnloadCacheClearer: FC = () => {
  useEffect(() => {
    const handlePageHide = (event: PageTransitionEvent) => {
      const authorizationHeader = credentials.getAuthorizationHeader();
      if (!authorizationHeader) {
        return;
      }

      const baseUrl = process.env.PROTECTED_API_URL || '';
      const payload = {
        headers: {
          Authorization: authorizationHeader,
          'x-app-id': getAppId(),
          'x-session-id': getSessionId(),
        },
        resources: [{ type: 'web' }, { type: 'global-fulltext' }, { type: 'library-fulltext' }, { type: 'research' }],
      };
      const url = `${baseUrl.replace(/\/$/, '')}/copilot/context-clear?payload=${btoa(JSON.stringify(payload))}`;

      if (navigator.sendBeacon) {
        navigator.sendBeacon(url);
      }
    };

    window.addEventListener('pagehide', handlePageHide);
  }, []);

  return null;
};

export default UnloadCacheClearer;
