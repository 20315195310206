import { useMemo } from 'react';
import { thumbnail as makeThumbnail } from 'utils';

export type ThumbnailSize = 'small' | 'medium' | 'large' | 'original';

export const useAvatarSrc = (src?: string, thumbnail?: ThumbnailSize): string | undefined => {
  return useMemo(() => {
    if (!thumbnail) {
      return src;
    }
    return makeThumbnail(src, thumbnail)?.url;
  }, [src, thumbnail]);
};
