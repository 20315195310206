import { shortenUrl } from 'utils';

import type { ResourceType, ItemAttribute, Resource } from './types';

export const prepareResources = (text: string) => {
  const patterns: { regex: RegExp; replace: string | any }[] = [
    {
      regex: /\[(content_id|playlist_id|note_id|source_url|web_search_id): ([\d, ]+|[A-f0-9-]{36}|https?:\/\/[^\s\]]+)]/g,
      replace: (match: string, idType: string, ids: string) => {
        return ids
          .split(',')
          .map((id: string) => `[${idType}:${id.trim()}]`)
          .join(', ');
      },
    },
    { regex: /\(content_id:\s*?(\d+)\)/g, replace: '[Material:$1]' },
    { regex: /\[(\d+)]/g, replace: '[Material:$1]' },
    { regex: /\((\d+)\)/g, replace: '[Material:$1]' },
    { regex: /\[content_id:\s*?(\d+)]/g, replace: '[Material:$1]' },
    { regex: /\[content_id:\s*?(N\/A|n\/a)]/g, replace: '' },
    { regex: /\(content_id:\s*?(\d+)\)/g, replace: '[Material:$1]' },
    { regex: /\[playlist_id:\s*?(\d+)]/g, replace: '[Collection:$1]' },
    { regex: /\(playlist_id:\s*?(\d+)\)/g, replace: '[Collection:$1]' },
    { regex: /\[note_id:\s*?(\d+)]/g, replace: '[Note:$1]' },
    { regex: /\(note_id:\s*?(\d+)\)/g, replace: '[Note:$1]' },
    { regex: /\[web_search_id:\s*?([A-f0-9-]{36})]/g, replace: '[RagMaterial:$1]' },
    { regex: /\[source_url:\s*?(https?:\/\/[^\s\]]+)]/g, replace: '[SourceUrl:$1]' },
  ];

  let result = text;

  patterns.forEach((pattern) => {
    result = result.replace(pattern.regex, pattern.replace);
  });

  return result;
};

type SourceItem = {
  type: 'Material' | 'Collection' | 'Note' | 'RagMaterial';
  id: number | string;
};

export const parseSources = (text: string, startSourcesState?: SourceItem[]) => {
  const result: SourceItem[] = [...(startSourcesState || [])];
  const ids: string[] = result.map((item) => `${item.type}::${item.id}`);
  const parseRegex = /\[(Material|Collection|Note|RagMaterial|SourceUrl):([\d, ]+|[A-f0-9-]{36}|https?:\/\/[^\s\]]+)]/g;

  Array.from(text.matchAll(parseRegex), (m) => [m[1], m[2]]).forEach(([type, id]) => {
    if (['Material', 'Collection', 'Note'].includes(type)) {
      if (ids.includes(`${type}::${id}`)) {
        return;
      }
      ids.push(`${type}::${id}`);
      result.push({
        type: type as 'Material' | 'Collection' | 'Note' | 'RagMaterial',
        id: Number(id),
      });
    }
    if (['RagMaterial'].includes(type)) {
      if (ids.includes(`${type}::${id}`)) {
        return;
      }
      ids.push(`${type}::${id}`);
      result.push({
        type: type as 'Material' | 'Collection' | 'Note' | 'RagMaterial',
        id,
      });
    }
  });

  return result;
};

export const setSourcesReferences = (text: string, sources?: SourceItem[]) => {
  const pool: string[] = [...(sources || [])].map((item) => `${item.type}::${item.id}`);
  let position = 1;
  const parseRegex = /\[(Material|Collection|Note|RagMaterial|SourceUrl):([\d, ]+|[A-f0-9-]{36}|https?:\/\/[^\s\]]+)]/g;

  return text.replaceAll(parseRegex, (_, type, key) => {
    if (['Material', 'Collection', 'Note', 'RagMaterial'].includes(type)) {
      const resourceKey = `${type}::${key}`;
      const index = pool.indexOf(resourceKey);
      position = index + 1;
      return `
        <span
          class="AnswerRefPoint-root"
          data-tm="resource-ref"
          data-resource-type="${type}"
          data-resource-id="${key}"
          data-ref-number="${position}"
        >
          <span class="AnswerRefPoint-text">${position}</span>
        </span>
      `
        .replace(/[\r\n]/gi, '')
        .replace(/\s{2,20}/gi, ' ')
        .trim();
    }
    if (['SourceUrl'].includes(type)) {
      return `
        <span
          class="AnswerRefPoint-link"
          title="${key}"
          data-tm="source-url"
          data-url="${key}"
        >
          <a href="${key}" title="${key}" target="_blank" rel="noopener noreferrer">${shortenUrl(key)}</a>
        </span>
      `
        .replace(/[\r\n]/gi, '')
        .replace(/\s{2,20}/gi, ' ')
        .trim();
    }
    return '';
  });
};

export const createItemIndex = (
  data:
    | { resourceType: ResourceType; resourceId?: number | null }
    | {
        resourceType: ResourceType;
        resourceId?: number | null;
        requestId: string;
        type: ItemAttribute['type'];
      }
    | {
        resourceType: ResourceType;
        resourceId?: number | null;
        itemId: string;
      },
) => {
  if ('itemId' in data) {
    return `${data.resourceType}::${data.resourceId || 'null'}::${data.itemId}`;
  }
  if ('requestId' in data && 'type' in data) {
    return `${data.resourceType}::${data.resourceId || 'null'}::${data.type}::${data.requestId}`;
  }
  return `${data.resourceType}::${data.resourceId || 'null'}::null::null`;
};

export const createItemId = (data: Pick<ItemAttribute, 'requestId' | 'type'>) => {
  if ('type' in data && 'requestId' in data) {
    return `${data.type}::${data.requestId}`;
  }
  return 'null::null';
};

export const createAttribute = (data: ItemAttribute): ItemAttribute => ({
  requestId: data.requestId,
  resourceType: data.resourceType,
  resourceId: data.resourceId,
  type: data.type,
});

export const createIndexByResource = (resources: Resource | Resource[]) =>
  (!Array.isArray(resources) ? [resources] : resources).map((resource) => `${resource.type}::${resource.id || 'null'}`).join(';');
