const memoOne = (fn, areEqual) => {
  const equal = areEqual || defaultAreEqual;
  let args, value;
  return function () {
    return !!args && equal(arguments, args) ? value : value = fn.apply(null, args = arguments);
  };
};

export default memoOne;

const defaultAreEqual = (current, prev) => current[0] === prev[0] && current[1] === prev[1] && current[2] === prev[2] && current[3] === prev[3];