import { type FC, memo, type MouseEvent, useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/joy';
import { type DefaultRootState, useStore } from 'react-redux';
import { isEqual } from 'lodash';

import Copilot from 'widgets/Copilot';
import Icon from 'ui/Icon';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import * as copilotStore from 'widgets/Copilot/store';
import type { AnswerType } from 'widgets/Copilot/store/types';
import textToHtml from 'widgets/Copilot/model/textToHtml';

import useIsMy from '../model/useIsMy';
import useIsEditable from '../model/useIsEditable';

type CopilotConnectorProps = {
  id: number | 'create';
  autofocus?: boolean;
  onCopyToText: (text: string) => void;
};

const CopilotConnector: FC<CopilotConnectorProps> = (props) => {
  const { id, autofocus, onCopyToText } = props;

  const store = useStore();
  const selectedContext = useSelector(noteStore.selectors.selectedContext);
  const isMy = useIsMy(id);
  const isEditable = useIsEditable(id);

  const handleAddToText = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const noteId = event.currentTarget?.getAttribute('data-id');
      if (!noteId) {
        return;
      }
      const data = copilotStore.selectors.dataById<AnswerType>(noteId)(store.getState() as DefaultRootState);
      if (!data?.payload?.text) {
        return;
      }
      onCopyToText?.(textToHtml(data.payload.text));
    },
    [store],
  );

  return (
    <Copilot
      variant={selectedContext}
      resourceId={id === 'create' || selectedContext === 'library-fulltext' ? 0 : id}
      slotsProps={{
        answer: {
          actionsBar: [
            isMy && isEditable && (
              <Tooltip arrow variant="outlined" size="sm" title="Move to text" disableInteractive>
                <IconButton onClick={handleAddToText}>
                  <Icon name="left" weight="regular" color="inherit" />
                </IconButton>
              </Tooltip>
            ),
            'Reload',
            'ToClipboard',
            (!isMy || !isEditable) && 'ToNote',
          ].filter(Boolean),
        },
        input: {
          autofocus,
        },
      }}
    />
  );
};

export default memo(CopilotConnector, (prevProps, nextProps) => isEqual(prevProps, nextProps));
