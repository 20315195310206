import { prepareHtml } from 'utils';

function removeResourceRefBlocks(html: string) {
  if (!html) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  if (!doc || !doc.body) {
    return html;
  }

  const elements = doc.body.querySelectorAll('[data-tm="resource-ref"], [data-tm="source-url"]');

  elements.forEach((el) => {
    if (el.getAttribute('data-tm') === 'resource-ref') {
      el.remove();
    }
    if (el.getAttribute('data-tm') === 'source-url') {
      const aTag = el.querySelector('a');
      if (!aTag) {
        return;
      }
      aTag.remove();
      el.parentNode?.insertBefore(aTag, el);
      el.remove();
    }
  });

  return doc.body.innerHTML.replace(/[\s\n\r]+\./g, '.');
}

function textToHtml(text: string) {
  return removeResourceRefBlocks(prepareHtml({ text }, { async: false, html: true }) || '');
}

export default textToHtml;
