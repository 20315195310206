import { memo, type PropsWithChildren, useMemo } from 'react';
import { Box, Tooltip, Typography, useColorScheme } from '@mui/joy';
import parse, { type DOMNode, type HTMLReactParserOptions, Element } from 'html-react-parser';

import { shortenUrl } from 'utils';

import TooltipCard from 'widgets/Copilot/elements/TooltipCard';
import useGlobalStyles from '../model/useGlobalStyles';

const Render = (domNode: DOMNode) => {
  const node = domNode as Element;

  if (node.attribs?.['data-tm']?.includes('resource-ref')) {
    const refNumber = Number(node.attribs['data-ref-number']);
    const resourceType = node.attribs['data-resource-type'] as 'Material' | 'RagMaterial' | 'Collection' | 'Note';
    const resourceId = node.attribs['data-resource-id'];

    if (resourceType === 'RagMaterial') {
      return (
        <Box className="AnswerRefPoint-root" data-type={resourceType} data-id={resourceId}>
          <Box className="AnswerRefPoint-text">{refNumber}</Box>
        </Box>
      );
    }

    return (
      <Tooltip
        arrow
        placement="left"
        variant="outlined"
        size="sm"
        title={<TooltipCard type={resourceType} id={resourceId} />}
        slotProps={{
          // @ts-ignore
          root: {
            sx: {
              padding: 0,
            },
          },
        }}
      >
        <Box className="AnswerRefPoint-root" data-type={resourceType} data-id={resourceId}>
          <Box className="AnswerRefPoint-text">{refNumber}</Box>
        </Box>
      </Tooltip>
    );
  }
  if (node.attribs?.['data-tm']?.includes('source-url')) {
    const url = node.attribs['data-url'];
    return (
      <Box component="span" className="AnswerRefPoint-link">
        <Typography component="a" href={url} target="_blank" variant="plain" rel="noopener noreferrer" title={url}>
          {shortenUrl(url)}
        </Typography>
      </Box>
    );
  }

  return null;
};

type CustomHtmlProps = {
  applyReplace?: boolean;
};

const RenderHtml = (props: PropsWithChildren<CustomHtmlProps>) => {
  const { children, applyReplace } = props;
  const { mode } = useColorScheme();

  useGlobalStyles();

  const options = useMemo(
    () =>
      ({
        replace: Render,
      }) as HTMLReactParserOptions,
    [Render],
  );

  return (
    <Box
      fontSize={17}
      lineHeight={1.4}
      color="var(--joy-palette-text-secondary)"
      sx={{
        '& strong, & b': {
          fontWeight: 500,
        },
        '& p': {
          my: 2,
        },
        '& h1': {
          fontSize: '1.75em',
          fontWeight: 500,
          my: '1em',
        },
        '& h2': {
          fontSize: '1.5em',
          fontWeight: 500,
          my: '1em',
        },
        '& h3': {
          fontSize: '1.25em',
          fontWeight: 500,
          my: '1em',
        },
        '& h4': {
          fontSize: '1.125em',
          fontWeight: 500,
          my: '1em',
        },
        '& h5': {
          fontWeight: 500,
          my: 2,
        },
        '& p:first-child, & h1:first-child, & h2:first-child, & h3:first-child, & h4:first-child, & h5:first-child': {
          mt: 0,
        },
        '& p:last-child, & h1:last-child, & h2:last-child, & h3:last-child, & h4:last-child, & h5:last-child': {
          mb: 0,
        },
        '& ol, ul': {
          listStyle: 'auto',
          my: 2,
          mr: 0,
          ml: 2.5,
          p: 0,
        },
        '& ol:first-child, ul:first-child': {
          mt: 0,
        },
        '& ol:last-child, ul:last-child': {
          mb: 0,
        },
        '& li': {
          mt: 1.5,
        },
        '& li:first-child': {
          mt: 0,
        },
        '& a': {
          color: mode === 'dark' ? 'var(--joy-palette-text-primary)' : undefined,
        },
      }}
    >
      {!applyReplace && typeof children === 'string' && parse(children)}
      {applyReplace && typeof children === 'string' && parse(children, options)}
    </Box>
  );
};

export default memo(RenderHtml);
