import createCachedSelector from 're-reselect';
import { type DefaultRootState } from 'react-redux';

const getMeta = (state: DefaultRootState, itemId: string) => state.copilot.meta[itemId];

const getCombinedItem = createCachedSelector(
  getMeta,
  (_: any, itemId) => itemId,
  (itemMeta, itemId) => {
    return {
      id: itemId,
      ...itemMeta,
    };
  },
)({
  keySelector: (_, itemId) => itemId,
});

export default <P = undefined>(itemId: string) =>
  (state: DefaultRootState) =>
    getCombinedItem(state, itemId);
