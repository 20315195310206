import { memo } from 'react';
import JoyAvatar, { type AvatarProps as JoyAvatarProps } from '@mui/joy/Avatar';
import { isEqual } from 'lodash';

import type { AuthorType, UserType } from 'app/entities';

import { useAvatarInitials } from './model/useAvatarInitials';
import { useAvatarSrc, type ThumbnailSize } from './model/useAvatarSrc';
import { useAvatarErrorFallback } from './model/useAvatarErrorFallback';
import { useAvatarParams } from './model/useAvatarParams';

type UserProps = {
  user: Pick<UserType, 'name' | 'surname' | 'photo'> | Pick<AuthorType, 'name' | 'surname' | 'photo' | 'owner'> | null;
  src?: never;
  name?: never;
  defaultName?: string;
};

type NameSrcProps = {
  user?: never;
  src?: string;
  name?: string;
  defaultName?: string;
};

type AvatarPropsBase = Omit<JoyAvatarProps, 'src'> & {
  thumbnail?: ThumbnailSize;
  vars?: {
    AvatarSize?: number | string;
    Background?: 1 | 2 | 3;
  };
};

// Создаём объединение типов для взаимоисключающих пропсов
type AvatarProps = AvatarPropsBase & (UserProps | NameSrcProps);

const Avatar = (props: AvatarProps) => {
  const { thumbnail, user, src, name, defaultName, vars, ...rest } = props;

  // Получаем конечные параметры из нашего нового хука
  const { finalName, finalSrc } = useAvatarParams({ user, src, name, defaultName });

  const srcFinal = useAvatarSrc(finalSrc, thumbnail);
  const initials = useAvatarInitials(finalName);
  const { error, onError } = useAvatarErrorFallback();

  const shouldShowInitials = !srcFinal || error;

  return (
    <JoyAvatar
      src={shouldShowInitials ? undefined : srcFinal}
      alt={finalName}
      sx={{
        ...(vars?.AvatarSize ? { '--Avatar-size': vars.AvatarSize } : undefined),
        ...(vars?.Background ? { '--variant-softBg': `var(--joy-palette-background-level${vars?.Background})` } : undefined),
      }}
      onError={onError}
      {...rest}
    >
      {shouldShowInitials ? initials : null}
    </JoyAvatar>
  );
};

export default memo(Avatar, (prevProps, nextProps) => isEqual(prevProps, nextProps));
