import { memo, useCallback } from 'react';
import { Card, CardContent, Box, Typography, useTheme } from '@mui/joy';

import { guard } from 'utils';
import useResourceData from 'hooks/useResourceData';
import useLoadData from 'widgets/Card/model/useLoadData';
import useDocumentDescription from 'widgets/Card/model/useDocumentDescription';

import openLink from '../model/openLink';

type TooltipCardProps = {
  type: 'Material' | 'RagMaterial' | 'Document' | 'Note' | 'Collection';
  id: number | string;
};

const MaterialBody = (props: { title?: string; description?: string }) => {
  const { title, description } = props;
  return (
    <>
      {title && (
        <Typography
          level="title-lg"
          id="card-description"
          display="-webkit-box"
          overflow="hidden"
          fontWeight={600}
          fontSize={14}
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          level="body-sm"
          aria-describedby="card-description"
          display="-webkit-box"
          overflow="hidden"
          fontSize={14}
          fontWeight={400}
          sx={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {description}
        </Typography>
      )}
    </>
  );
};

const DocumentBody = (props: { previewText?: string; text?: string }) => {
  const { previewText, text } = props;
  const { title, paragraph, hasContent } = useDocumentDescription((previewText || text || '').trim());
  return (
    <>
      {title && (
        <Typography
          level="title-lg"
          id="card-description"
          display="-webkit-box"
          overflow="hidden"
          fontWeight={600}
          fontSize={14}
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {title}
        </Typography>
      )}
      {paragraph && (
        <Typography
          level="body-sm"
          aria-describedby="card-description"
          display="-webkit-box"
          overflow="hidden"
          fontSize={14}
          fontWeight={400}
          sx={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {paragraph}
        </Typography>
      )}
    </>
  );
};

const NoteBody = (props: { previewText?: string; text?: string }) => {
  const { previewText, text } = props;
  const { title, paragraph, hasContent } = useDocumentDescription((previewText || text || '').trim());
  return (
    <>
      {title && (
        <Typography
          level="title-lg"
          id="card-description"
          display="-webkit-box"
          overflow="hidden"
          fontWeight={600}
          fontSize={14}
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {title}
        </Typography>
      )}
      {paragraph && (
        <Typography
          level="body-sm"
          aria-describedby="card-description"
          display="-webkit-box"
          overflow="hidden"
          fontSize={14}
          fontWeight={400}
          sx={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {paragraph}
        </Typography>
      )}
    </>
  );
};

const TooltipCard = (props: TooltipCardProps) => {
  const { type, id } = props;
  const theme = useTheme();

  useLoadData(id, type);
  const data = useResourceData(id, type);

  const handleClick = useCallback(() => {
    if (!data) {
      return;
    }
    if ((guard.isMaterial(data) || guard.isNote(data) || guard.isDocument(data)) && data.internalUrl) {
      openLink(data.internalUrl, '_blank');
    }
  }, [data]);

  return (
    <Card
      variant="plain"
      onClick={handleClick}
      orientation="horizontal"
      sx={{
        width: 320,
        cursor: 'pointer',
        alignItems: 'center',
      }}
    >
      <CardContent>
        <Box display="flex" m={-2}>
          {guard.isMaterial(data) && data?.image?.url && (
            <img
              src={data.image.url}
              loading="lazy"
              alt=""
              style={{
                height: 124,
                aspectRatio: 1,
                objectFit: 'cover',
                borderRadius: theme.radius.sm,
              }}
            />
          )}
          <Box display="flex" flexDirection="column" gap={1} pt={1} pb={!guard.isMaterial(data) || !data?.image?.url ? 1 : 0} px={1.5}>
            {guard.isMaterial(data) && <MaterialBody title={data.title} description={data.description} />}
            {guard.isDocument(data) && <DocumentBody text={data.text} previewText={data.previewText} />}
            {guard.isNote(data) && <NoteBody text={data.text} previewText={data.previewText} />}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(TooltipCard);
