import { useState, useCallback } from 'react';

export const useAvatarErrorFallback = () => {
  const [error, setError] = useState(false);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  return { error, onError };
};
