function shortenUrl(url: string, maxLength = 60, pathStart = 4, pathEnd = 12) {
  try {
    const urlObj = new URL(url);

    const base = `${urlObj.protocol}//${urlObj.host}`;

    const path = urlObj.pathname + urlObj.search + urlObj.hash;

    if ((base + path).length <= maxLength) {
      return url;
    }

    let startChunk = path.slice(0, pathStart);
    let endChunk = path.slice(-pathEnd);

    startChunk = startChunk.replace(/-+$/, '');
    endChunk = endChunk.replace(/^-+/, '');

    const shortPath = `${startChunk}...${endChunk}`;

    let shortenedUrl = base + shortPath;

    if (shortenedUrl.length > maxLength) {
      shortenedUrl = `${shortenedUrl.slice(0, maxLength - 3)}...`;
    }

    return shortenedUrl;
  } catch (err) {
    return url;
  }
}

export default shortenUrl;
