import type { DefaultRootState } from 'react-redux';
import type { DocumentType, NoteType } from 'app/entities';

import { guard } from 'utils';

import { useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';
import * as searchStore from 'store/nodes/search';

const useResourceData = (id?: number | string, type?: 'Material' | 'RagMaterial' | 'Document' | 'Note' | 'Collection') => {
  return useSelector((state: DefaultRootState) => {
    if (!type || !id) {
      return null;
    }
    if (guard.resourceKeyIsMaterial(type)) {
      return contentStore.selectors.dataById(id as number)(state);
    }
    if (guard.resourceKeyIsCollection(type)) {
      return collectionStore.selectors.dataById(id as number)(state);
    }
    if (guard.resourceKeyIsNote(type)) {
      return noteStore.selectors.dataById(id as number)(state) as NoteType | null;
    }
    if (guard.resourceKeyIsDocument(type)) {
      return noteStore.selectors.dataById(id as number)(state) as DocumentType | null;
    }
    if (guard.resourceKeyIsRagMaterial(type)) {
      return searchStore.selectors.dataById(id as number | string)(state);
    }
    return null;
  });
};

export default useResourceData;
