import { v4 as uuidV4 } from 'uuid';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { difference } from 'lodash';

import { prepareResources, parseSources, setSourcesReferences, createItemIndex, createAttribute, createItemId } from './utils';
import type { ResourceType, ItemAttribute, ItemId, ItemIndex, ItemPayload, ItemData, ModelType, Resource, ItemMeta } from './types';

export type CopilotStore = {
  rightBar: {
    show: boolean;
    width: number;
  };
  payload: Record<ItemId, ItemPayload>;
  attribute: Record<ItemId, ItemAttribute>;
  indexMap: Record<ItemIndex, ItemId>;
  typingMap: Record<ItemId, ItemAttribute>;
  meta: Record<ItemId, ItemMeta>;
  sequence: ItemId[];
  model: {
    selectedId: number | null;
    available: ModelType[];
  };
};

const initialState: CopilotStore = {
  rightBar: {
    show: true,
    width: 0,
  },
  payload: {},
  attribute: {},
  indexMap: {},
  typingMap: {},
  meta: {},
  sequence: [],
  model: {
    selectedId: null,
    available: [],
  },
};

const slice = createSlice({
  name: 'copilot',
  initialState,
  reducers: {
    close: (state) => {
      state.rightBar.show = false;
    },
    open: (state) => {
      state.rightBar.show = true;
    },
    clear: (state, action: PayloadAction<{ resources: Resource | Resource[] } | undefined>) => {
      const { resources } = action?.payload || {};
      if (!Array.isArray(resources) || resources.length === 0) {
        state.payload = {};
        state.attribute = {};
        state.indexMap = {};
        state.sequence = [];
        return;
      }
      const removeIds: ItemId[] = [];
      resources.forEach((item) => {
        Object.keys(state.indexMap)
          .filter((key) => key.includes(`${item.type}::${item.id || 'null'}`))
          .forEach((removeIndex) => {
            removeIds.push(state.indexMap[removeIndex]);
            delete state.indexMap[removeIndex];
          });
      });
      removeIds.forEach((itemId) => {
        delete state.payload[itemId];
        delete state.attribute[itemId];
      });
      state.sequence = difference(state.sequence, removeIds);
    },
    toggle: (state) => {
      state.rightBar.show = !state.rightBar.show;
    },
    setWidth: (state, action) => {
      state.rightBar.width = action.payload;
    },
    addItem: (state, action: PayloadAction<ItemData>) => {
      const itemId = createItemId(action.payload);
      const { requestId, resourceType, resourceId, type, ...payload } = action.payload;
      state.payload[itemId] = {
        ...payload,
        type,
      } as ItemPayload;
      state.attribute[itemId] = createAttribute({
        requestId,
        resourceType,
        resourceId,
        type,
      });
      state.indexMap[createItemIndex(action.payload)] = itemId;
      state.sequence.push(itemId);
    },
    addItemDone: () => undefined,
    sendQuestion: (
      state,
      action: PayloadAction<{
        reloadId?: string;
        resourceType: ResourceType;
        resourceId?: number | null;
        text: string;
      }>,
    ) => undefined,
    sendCommand: (
      state,
      action: PayloadAction<{
        reloadId?: string;
        resourceType: ResourceType;
        resourceId?: number | null;
        label: string;
        text: string;
      }>,
    ) => undefined,
    answerError: (state, action: PayloadAction<{ requestId: string; type: 'unauthorized' }>) => {
      const { requestId, type } = action.payload;
      const itemId = createItemId({ requestId, type: 'answer' });
      const foundIndex = state.sequence.indexOf(itemId);
      if (foundIndex === -1) {
        return;
      }
      const itemPayload = state.payload[itemId];
      const itemAttribute = state.attribute[itemId];
      if (itemPayload.type !== 'answer' || itemPayload.status !== 'typing') {
        return;
      }
      delete state.payload[itemId];
      delete state.attribute[itemId];
      delete state.indexMap[createItemIndex(itemAttribute)];
      delete state.typingMap[itemId];
      state.sequence.splice(foundIndex, 1);

      const errorItemId = createItemId({ requestId, type });
      state.payload[errorItemId] = {
        text: '',
        type,
      } as ItemPayload;
      state.attribute[errorItemId] = createAttribute({
        ...itemAttribute,
        type,
      });
      state.indexMap[createItemIndex(state.attribute[errorItemId])] = errorItemId;
      state.sequence.push(errorItemId);
    },
    answerStart: (
      state,
      action: PayloadAction<{
        requestId: string;
        resourceType: ResourceType;
        resourceId?: number | null;
      }>,
    ) => {
      const { requestId, resourceType, resourceId } = action.payload;
      const itemId = createItemId({ requestId, type: 'answer' });
      state.payload[itemId] = {
        text: '',
        status: 'typing',
        sources: [],
        type: 'answer',
      };
      state.attribute[itemId] = createAttribute({
        requestId,
        resourceType,
        resourceId: resourceId || null,
        type: 'answer',
      });
      state.typingMap[itemId] = createAttribute({
        requestId,
        resourceType,
        resourceId: resourceId || null,
        type: 'answer',
      });
      state.meta[itemId] = {
        bufferId: uuidV4(),
        bufferSize: 0,
      };
      state.indexMap[createItemIndex({ resourceType, resourceId, itemId })] = itemId;
      state.sequence.push(itemId);
    },
    answerAddChunk: (state, action: PayloadAction<{ requestId: string; text: string }>) => {
      const { requestId, text } = action.payload;
      const itemId = createItemId({ requestId, type: 'answer' });
      const itemPayload = state.payload[itemId];
      const itemAttribute = state.attribute[itemId];
      const itemMeta = state.meta[itemId];
      if (itemPayload?.type !== 'answer' || itemPayload.status === 'done') {
        return;
      }
      itemPayload.text += text;
      itemPayload.status = 'typing';
      if (itemMeta.bufferSize > 50) {
        const prepared = prepareResources(itemPayload.text);
        itemPayload.sources = parseSources(prepared, itemPayload.sources);
        itemPayload.text = setSourcesReferences(prepared, itemPayload.sources);
        itemMeta.bufferId = uuidV4();
        itemMeta.bufferSize = 0;
      } else {
        itemMeta.bufferSize += text.length;
      }
      state.typingMap[itemId] = itemAttribute;
    },
    answerEnd: (state, action: PayloadAction<{ requestId: string }>) => {
      const { requestId } = action.payload;
      const itemId = createItemId({ requestId, type: 'answer' });
      const itemPayload = state.payload[itemId];
      if (itemPayload?.type !== 'answer' || itemPayload.status === 'done') {
        return;
      }
      const prepared = prepareResources(itemPayload.text);
      itemPayload.status = 'done';
      itemPayload.sources = parseSources(prepared, itemPayload.sources);
      itemPayload.text = setSourcesReferences(prepared, itemPayload.sources);
      delete state.typingMap[itemId];
      delete state.meta[itemId];
    },
    stop: (state, action: PayloadAction<{ resources: { type: ResourceType; id?: number }[] } | undefined>) => {
      Object.keys(state.typingMap).forEach((itemId) => {
        const itemPayload = state.payload[itemId];
        if (itemPayload?.type === 'answer') {
          itemPayload.status = 'done';
        }
        delete state.typingMap[itemId];
      });
    },
    reloadAnswer: (state, action: PayloadAction<{ requestId: string }>) => {
      const { requestId } = action.payload;
      const itemId = createItemId({ requestId, type: 'answer' });
      const foundIndex = state.sequence.indexOf(itemId);
      if (foundIndex === -1) {
        return;
      }
      const itemPayload = state.payload[itemId];
      const itemAttribute = state.attribute[itemId];
      if (itemPayload.type !== 'answer' || itemPayload.status !== 'done') {
        return;
      }
      delete state.payload[itemId];
      delete state.attribute[itemId];
      delete state.indexMap[createItemIndex(itemAttribute)];
      state.sequence.splice(foundIndex, 1);
    },
    saveAnswerToNote: (state, action: PayloadAction<{ requestId: string }>) => undefined,
    loadSelectedModel: (state) => undefined,
    loadSelectedModelDone: (state, action: PayloadAction<{ model: ModelType } | undefined>) => {
      const { model } = action.payload || {};
      if (!model) {
        return;
      }
      state.model.selectedId = model.id;
      const foundIndex = state.model.available.findIndex((item) => item.id === model.id);
      if (foundIndex === -1) {
        state.model.available.push(model);
      }
    },
    loadAvailableModels: (state) => undefined,
    loadAvailableModelsDone: (state, action: PayloadAction<{ models: ModelType[] } | undefined>) => {
      const { models } = action.payload || {};
      if (models) {
        state.model.available = models;
      }
    },
    setSelectedModel: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const foundIndex = state.model.available.findIndex((item) => item.id === id);
      if (foundIndex === -1) {
        return;
      }
      state.model.selectedId = id;
    },
    setSelectedModelDone: (state, action: PayloadAction<{ id: number } | undefined>) => {
      const { id } = action.payload || {};
      if (typeof id === 'undefined') {
        return;
      }
      const foundIndex = state.model.available.findIndex((item) => item.id === id);
      if (foundIndex === -1) {
        return;
      }
      state.model.selectedId = id;
    },
  },
});

export const { reducer, actions } = slice;
