import { useMemo } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import prepareHtml from 'utils/prepareHtml';

const renderMath = (text: string): string => {
  let result = text;
  const templateRegex = /\$\$([\s\S]*?)\$\$/g;
  const renderFormula = (match: string, p1: string, displayMode: boolean): string => {
    try {
      return katex.renderToString(p1.trim(), { displayMode });
    } catch (error) {
      console.error('KaTeX rendering error:', error);
      return match;
    }
  };
  result = result.replace(templateRegex, (match, p1) => renderFormula(match, p1, false));
  return result;
};

const useAnswerData = (text: string, status: 'waiting' | 'typing' | 'done') =>
  useMemo(() => {
    let html = text;
    if (!html) {
      return {
        html: '',
      };
    }

    html = prepareHtml({ text: html }, { async: false, html: true }) || '';

    if (status === 'done') {
      html = renderMath(html);
    }

    return {
      html,
    };
  }, [text, status]);

export default useAnswerData;
