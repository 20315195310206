class OneKeyMap {
  constructor() {
    this.set = void 0;
    this.get = void 0;
    let key, val;

    this.get = k => k === key ? val : void 0;

    this.set = (k, v) => {
      key = k;
      val = v;
    };
  }

}

export default OneKeyMap;