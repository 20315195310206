import { useEffect } from 'react';

let globalStylesAdded = false;

function useGlobalStyles() {
  useEffect(() => {
    if (!globalStylesAdded) {
      globalStylesAdded = true;

      const style = document.createElement('style');
      style.innerHTML = `
        .AnswerRefPoint-root {
          background: var(--ref-background-color);
          transition: 300ms background ease;
          display: inline-block;
          width: 20px;
          height: 20px;
          font-size: 12px;
          vertical-align: bottom;
          text-align: center;
          border-radius: 50%;
          opacity: 0.9;
          transform: translateY(-2px);
        }
        .AnswerRefPoint-text {
          color: currentColor;
          font-family: inherit;
          display: block;
          transform: translateY(1px);
          font-size: inherit;
          font-weight: 600;
        }
        .AnswerRefPoint-link {
          font-size: 1rem;
        }
        .AnswerRefPoint-link a {
          display: initial;
          text-decoration: underline;
          margin: unset;
          margin-inline: unset;
          padding: unset;
          font-size: inherit;
          font-weight: 500;
        }
        .AnswerRefPoint-link a:hover {
          text-decoration: none;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);
}

export default useGlobalStyles;
